import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Box, Container } from '@material-ui/core';
import { Link } from 'gatsby-theme-material-ui';
import { useLocation } from '@reach/router';

const overlayNavVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 0.3 },
  },
};

const OverlayNavStyled = styled(motion.nav)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100vh;
  background: black;

  ul {
    color: white;
    list-style-type: none;

    li {
      a {
        display: block;
        padding: 0.5rem 0;
        color: lightgray;
        font-size: 2rem;
      }
    }
  }
`;

const OverlayNav = ({ open, setOpen }) => {
  const location = useLocation();

  const handleClick = (e) => {
    if (e.target.href.includes(location.pathname)) setOpen(false);
  };

  useEffect(
    () => () => {
      setOpen(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]
  );

  return (
    <OverlayNavStyled
      open={open}
      variants={overlayNavVariants}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      <Container maxWidth="md">
        <Box p={2} mt={8} component="ul">
          <li>
            <Link to="/" onClick={handleClick}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/empresas" onClick={handleClick}>
              Empresas
            </Link>
          </li>
          <li>
            <Link to="/sobre" onClick={handleClick}>
              Sobre
            </Link>
          </li>
          <li>
            <Link to="/diretoria" onClick={handleClick}>
              Diretoria
            </Link>
          </li>
          <li>
            <Link to="/eventos" onClick={handleClick}>
              Eventos
            </Link>
          </li>
          <li>
            <Link to="/contato" onClick={handleClick}>
              Contato
            </Link>
          </li>
        </Box>
      </Container>
    </OverlayNavStyled>
  );
};

OverlayNav.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default OverlayNav;
