import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AnimatePresence, usePresence } from 'framer-motion';

import { useTheme } from '@material-ui/core/styles';
import { Box, useMediaQuery } from '@material-ui/core';
import { Link } from 'gatsby-theme-material-ui';
import HamburgerMenu from 'react-hamburger-menu';
import OverlayNav from './OverlayNav';
import Menu from './Menu';

const NavStyled = styled.nav`
  position: fixed;
  top: 0;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: ${({ theme }) => `${theme.mixins.toolbar.minHeight}px`};
  background: white;
`;

const HamburgerMenuStyled = styled(HamburgerMenu)`
  margin: 0 1rem;
`;

const LogoStyled = styled(Link)`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 33px 1rem;
  color: white;
  font-weight: bolder;
  letter-spacing: 0.15rem;
  text-transform: uppercase;
  background: ${({ theme, open }) =>
    open ? 'black' : theme.palette.primary.main};
  transition: ${({ theme }) =>
    `background ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}`};

  a {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
  }
`;

const Navbar = () => {
  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const handleClick = () => setOpen(!open);

  useEffect(() => {
    // setOpen(false);
  }, []);

  return (
    <>
      <NavStyled open={open} id="appbar">
        <LogoStyled to="/" open={open}>
          Alphanorth
        </LogoStyled>
        {matches ? (
          <Menu />
        ) : (
          <Box>
            <HamburgerMenuStyled
              isOpen={open}
              menuClicked={handleClick}
              width={30}
              height={15}
              strokeWidth={3}
              color={open ? 'lightgray' : 'black'}
            />
          </Box>
        )}
      </NavStyled>

      <AnimatePresence exitBeforeEnter>
        {open && <OverlayNav open={open} setOpen={setOpen} />}
      </AnimatePresence>
    </>
  );
};

export default Navbar;
