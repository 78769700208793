import React from 'react';
import { ThemeProvider as MuiTheme } from '@material-ui/core/styles';
import { AnimatePresence } from 'framer-motion';
import { ThemeProvider } from 'styled-components';
import { CssBaseline } from '@material-ui/core';
import { defaultTheme } from './src/theme/theme';
import Layout from './src/components/layout/layout';

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={defaultTheme}>
    <CssBaseline />
    <MuiTheme theme={defaultTheme}>{element}</MuiTheme>
  </ThemeProvider>
);

export const wrapPageElement = ({ element }) => (
  <>
    <AnimatePresence>
      <Layout>{element}</Layout>
    </AnimatePresence>
  </>
);

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
    console.log(`# IntersectionObserver is polyfilled!`);
  }
};
