import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby-theme-material-ui';

const MenuStyled = styled.ul`
  display: flex;
  margin-right: 2rem;
  font-weight: bolder;
  font-family: 'arboria', 'roboto', 'sans-serif';
  text-transform: uppercase;

  li {
    margin: 0;
    padding: 0 1rem;
    list-style-type: none;
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }

  .active {
    &:after {
      position: absolute;
      bottom: 0;
      display: block;
      width: 1.5rem;
      height: 5px;
      background: lightgray;
      content: ' ';
    }
  }
`;

const pages = [
  { name: 'Home', link: '/' },
  { name: 'Sobre', link: '/sobre' },
  { name: 'Empresas', link: '/empresas' },
  { name: 'Diretoria', link: '/diretoria' },
  { name: 'Eventos', link: '/eventos' },
  { name: 'Contato', link: '/contato' },
];

const Menu = () => (
  <MenuStyled>
    {pages.map((page) => (
      <li key={page.name}>
        <Link to={page.link} activeClassName="active">
          {page.name}
        </Link>
      </li>
    ))}
  </MenuStyled>
);

export default Menu;
