import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Container, Grid, Typography } from '@material-ui/core';
import { Link } from 'gatsby-theme-material-ui';
import { EmailOutlined, Phone, RoomOutlined } from '@material-ui/icons';
import { graphql, useStaticQuery } from 'gatsby';
import IconInfo from '../../ui/IconInfo';

const pages = [
  { name: 'Home', link: '/' },
  { name: 'Sobre', link: '/sobre' },
  { name: 'Empresas', link: '/empresas' },
  { name: 'Diretoria', link: '/diretoria' },
  { name: 'Eventos', link: '/eventos' },
  { name: 'Contato', link: '/contato' },
];

const FooterStyled = styled.footer`
  margin-top: 2rem;
  padding: 4rem 1rem;
  color: white;
  background: #133828;

  .logo {
    width: 125px;
    height: 125px;
    margin-bottom: 2rem;
    overflow: hidden;
    background: white;
    border-radius: 50%;

    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }
  }
`;

const ListStyled = styled.ul`
  margin: 0;
  margin-bottom: 2rem;
  padding: 0;

  li {
    margin: 0;
    padding: 0 0 0.25rem 0;
    font-size: 1.25rem;
    list-style-type: none;

    a {
      color: white;
      transition: 0.2s all ease-out;

      &:hover {
        color: green;
        text-decoration: none;
      }
    }

    &:first-of-type {
      padding-top: 0;
    }
  }
`;

const Footer = () => {
  const { datoCmsSetting } = useStaticQuery(graphql`
    {
      datoCmsSetting {
        address
        phone
        email
        logo {
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
  `);

  return (
    <FooterStyled>
      <Container>
        <Grid container>
          <Grid item xs={12} md={4}>
            <div className="logo">
              <Img fluid={datoCmsSetting.logo.fluid} alt="" />
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <ListStyled>
              {pages.map((item) => (
                <li key={item.link}>
                  <Link to={item.link}>{item.name}</Link>
                </li>
              ))}
            </ListStyled>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h4" paragraph>
              Alphanorth
            </Typography>
            <IconInfo icon={<RoomOutlined />} text={datoCmsSetting.address} />
            <IconInfo icon={<Phone />} text={datoCmsSetting.phone} />
            <IconInfo
              icon={<EmailOutlined />}
              text={datoCmsSetting.email}
              mail
            />
          </Grid>
        </Grid>
      </Container>
    </FooterStyled>
  );
};

export default Footer;
