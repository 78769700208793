import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import ReactGA from 'react-ga';

const LGPD = () => {
  const [open, setOpen] = useState(false);

  const handleLgpd = (option) => {
    switch (option) {
      case 'ALLOW':
        localStorage.setItem('lgpd', true);
        ReactGA.initialize('UA-179977846-1');
        setOpen(false);
        break;
      case 'DISALLOW':
        localStorage.setItem('lgpd', false);
        setOpen(false);
        break;
      default:
    }
  };

  useEffect(() => {
    if (!localStorage.getItem('lgpd')) {
      setOpen(true);
    }
  }, []);

  return open ? (
    <Box position="fixed" bottom={0} width="100%" zIndex={99999} padding={2}>
      <Box
        bgcolor="#eaeaea"
        borderRadius="8px"
        width="60%"
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        margin="0 auto"
        padding="0 2rem"
        boxShadow="3px 1px 36px -9px rgba(0,0,0,0.53)"
      >
        <Box width="50%">
          <Typography variant="body2" style={{ padding: '1rem 0' }}>
            Este site usa cookies para proporcionar a você e outros usuários uma
            experiência mais relevante.
          </Typography>
        </Box>
        <Box width="30%">
          <Button
            variant="contained"
            size="small"
            color="primary"
            disableElevation
            onClick={() => handleLgpd('ALLOW')}
            style={{ margin: '0.5rem', fontSize: '0.8rem' }}
          >
            Aceitar Cookies
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="inherit"
            onClick={() => handleLgpd('DISALLOW')}
            style={{ margin: '0.5rem', fontSize: '0.8rem' }}
          >
            Recusar
          </Button>
        </Box>
      </Box>
    </Box>
  ) : null;
};

export default LGPD;
