import { createMuiTheme } from '@material-ui/core/styles';

// Fonts
const acumin = `'acumin-pro', 'roboto', 'sans-serif'`;
const arboria = `'arboria', 'roboto', 'sans-serif'`;

// Colors
const primaryColor = '#133828';

export const defaultTheme = createMuiTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: acumin,
    headingFont: arboria,
    h1: {
      fontFamily: arboria,
      color: primaryColor,
      textTransform: 'uppercase',
    },
    h2: {
      fontFamily: arboria,
    },
    h3: {
      fontFamily: arboria,
      fontSize: '2.5rem',
      color: primaryColor,
      textTransform: 'uppercase',
    },
    button: {
      fontFamily: arboria,
      fontWeight: 'bolder',
      textTransform: 'inherit',
      letterSpacing: '0.05rem',
    },
    lead: {
      color: 'red',
    },
  },
  mixins: {
    toolbar: {
      minHeight: 65,
    },
  },
});
