import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Header from './header/header';
import LGPD from './LGPD';
import Footer from './Footer';

const Layout = ({ children }) => (
  <>
    <LGPD />
    <Header />
    <Box minHeight="100vh">{children}</Box>
    <Footer />
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
