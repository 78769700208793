import React from 'react';
import styled from 'styled-components';

import Navbar from './navigation/Navbar';

const HeaderStyled = styled.header`
  margin-bottom: ${({ theme }) => `${theme.mixins.toolbar.minHeight}px`};
`;

const Header = () => (
  <HeaderStyled>
    <Navbar />
  </HeaderStyled>
);

export default Header;
