import { Box, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const IconInfo = ({ icon, text, link, mail }) => {
  if (mail) {
    return (
      <Box display="flex" alignItems="center">
        <div style={{ padding: '0.25rem 0.5rem 0.3rem 0' }}>{icon}</div>
        <a
          href={`mailto:${text}`}
          style={{ color: 'inherit', textDecoration: 'none' }}
        >
          <Typography variant="body2" style={{ marginBottom: '10px' }}>
            {text}
          </Typography>
        </a>
      </Box>
    );
  }

  if (link) {
    return (
      <Box display="flex" alignItems="center">
        <div style={{ padding: '0.25rem 0.5rem 0.3rem 0' }}>{icon}</div>
        <a
          href={`http://${link}`}
          rel="noopener noreferrer"
          target="_blank"
          style={{ color: 'green' }}
        >
          {text}
        </a>
      </Box>
    );
  }

  return (
    <Box display="flex" alignItems="center">
      <div style={{ padding: '0.25rem 0.5rem 0.3rem 0' }}>{icon}</div>
      {link ? (
        <a href={`http://${link}`} rel="noopener noreferrer" target="_blank">
          {text}
        </a>
      ) : (
        <Typography variant="body2" style={{ marginBottom: '10px' }}>
          {text}
        </Typography>
      )}
    </Box>
  );
};

IconInfo.propTypes = {
  icon: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  mail: PropTypes.bool,
  link: PropTypes.string,
};

export default IconInfo;
